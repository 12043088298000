.triangle {
    width: 100%;
    fill: rgb(25, 31, 25);
    height: 0;
    transition: height 1s ease;
}

.triangleBackground {
    background-color: rgb(0, 200, 200);
    height: 250px;
}