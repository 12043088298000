body, html {
  margin: 0;
  font-family: 'Roboto Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(25, 31, 25);
  overflow-x: hidden;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  display: block;
  width: 100%;
  opacity: 1;
  visibility: visible;
  transition: visibility 0.3s ease, opacity 0.3s ease 1.5s;
  scroll-behavior: smooth;
}

.maxWidthContainer {
  margin: auto;
  max-width: 1200px;
  width: 100%;
  height: 100%;
}

.home {
  background-color: rgb(0, 200, 200);
}

.hide {
  transition: visibility 0.3s ease, opacity 0.3s ease;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}

.transferLogoScreen {
  position: fixed;
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgb(25, 31, 25);
  justify-content: center;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.5s ease;
}


.showScreen {
  opacity: 1;
  z-index: 1;
}

.hideScreen {
  opacity: 0;
}

.transferLogo {
  width: 50px;
  transform: translateY(-5vh);
  transition: transform 0.5s ease;
}

.showLogo {
  transform: translateY(0);
}

.hideLogo {
  transform: translateY(5vh);
}