#quicktypeContainer {
    color: white;
    height: 150vh;
    background-color: rgb(25, 31, 25);
}

.quicktypeFlex {
    padding: 15px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content:space-around;
}

.quicktype {
    width: 60%;
    height: 70vh;
    transform: translateX(20%);
    opacity: 0;
    transition: opacity 1s ease, transform 1s ease;
}

.showType {
    opacity: 1;
    transform: translateX(0);
}

.projectDesc {
    margin-bottom: 30px;
}

.projectText {
    padding: 15px;
    text-align: center;
    width: 30%;
    border-right: 1px solid white;
}

.projectText > * {
    opacity: 0;
    transform: translateY(-20%);
    transition: opacity 1s ease, transform 1s ease;
}

.showQtText {
    transform: translateY(0);
    opacity: 1;
}

#qtLink {
    width: 24px;
    height: 24px;
    margin-left: 5px;
}

.button {
    background-color: rgb(0, 200, 200);
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: medium;
    display: flex;
    align-items: center;
    margin: auto;
    transition: background-color 0.2s ease, color 0.2s ease, opacity 1s ease, transform 1s ease;
}

.button:hover {
    background-color:rgb(0, 150, 150);
    cursor: pointer;
}

@media only screen and (max-width: 620px){
    .quicktypeFlex {
        flex-direction: column;
        justify-content: center;
    }

    .projectText {
        width: 80%;
        border: none;
        border-bottom: 1px solid white;
    }

    .quicktype {
        margin: 0;
        margin-top: 15px;
        width: 95%;
    }
}