.typedContainer {
    background-color: rgb(25, 31, 25);
    height: 100vh;
    position: relative;
}

.centre {
    margin: auto;
    text-align: center;
}

.typingBoxContainer {
    display: flex;
    flex-grow: 1;
    align-items: center;
    height: 100%;
}

.centreTyping {
    display: inline-block;
    font-size: 50px;
    line-height: 70px;
    height: 230px;
    color: white;
    margin: 0 15px;
    user-select: none;
}

.centreTyping p {
    margin: 0;
}

.typingLetters {
    display: flex;
    color: rgb(0, 200, 200);
    align-items: center;
    flex-wrap: wrap;
    max-width: 100vw;
    margin: 10px 0;
}

.typingLetters p {
    margin: 0;
    margin-right: 30px;
}

.typingLetters p:last-of-type {
    margin: 0;
}


.cursor {
    background-color:rgb(0, 200, 200);
    height: 70px;
    width: 5px;
    display: inline;
    margin: 0 5px;
}

.breathing {
    animation: cursorFade 1s ease infinite;
}

@keyframes cursorFade {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (min-width: 992px){

    .typingBoxContainer {
        justify-items: center;
    }

    .centreTyping{
        width: 600px;
        margin: auto;
    }

}