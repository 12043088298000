.contact {
    background-color: rgb(38, 46, 38);
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }

.contactBox {
    width: 90%;
    max-width: 600px;
    /* min-height: 400px; */
    background-color: rgb(25, 31, 25);
    box-shadow: 10px 10px black;
    color: white;
    text-align: center;
    padding: 15px;
    z-index: 0;
}

#contactCanvas {
    position: absolute;
}

.underline {
    width: 0;
    height: 5px;
    margin: auto;
    background-color: white;
    transition: width 1s ease;
}

.underlineGrow {
    width: 100%;
}

.contactTitle {
    display: inline-block;
}

.contactTitle h1 {
    margin: 10px;
}