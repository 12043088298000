#experienceContainer {
    background-color: rgb(0, 200, 200);
    width: 100%;
    height: 1800px;
    position: relative;
    display: inline-block;
    overflow-x: hidden;
}

#testCanvas {
    background-color: rgb(0, 200, 200);
    position: absolute;
    height: 100%;
    width: auto;
}

.expFlexContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}

.expSection {
    width: 85%;
    color: white;
    background-color: rgb(25, 31, 25);
    margin: 40px;
    max-width: 600px;
    min-height: 200px;
    display: flex;
    padding: 15px;
    box-shadow: 10px 10px black;
    opacity: 0;
    transform: translateX(20%);
    transition: transform 1s ease, opacity 1s ease;
}

.expEven {
    transform: translateX(-20%);
}

.slideIn {
    opacity: 1;
    transform: translateX(0);
}

.expTitle {
    border-right: 1px solid white;
    writing-mode: vertical-lr;
    text-align: center;
    font-size: xx-large;
    padding: 15px;
    padding-left: 0px;
}

.expDesc {
    padding: 25px;
    padding-right: 0px;
    display: flex;
    align-items: center;
}

.expDesc div {
    margin: auto;
}

.expDesc ul li::before {
    content: "- ";
}

.expDesc ul {
    list-style-type: none;
}

.about, .uniExp, .languageExp, .interests {
    margin: 40px auto;
}

.profilePic {
    width: 40vw;
    max-width: 120px;
    height: auto;
    margin: 15px;
    padding: 25px 0;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    filter: grayscale(100%);
}

@media only screen and (max-width: 450px){
    .about .expDesc {
        flex-direction: column;
    }
}

@media only screen and (min-width: 992px){

    .about, .uniExp {
        margin: 40px;
    }

    .languageExp, .interests {
        margin: 40px;
        margin-left: auto;
    }


}