#arrow {
    width: 100px;
    fill: white;
    position: absolute;
    bottom: 5%;
    left: 50%;
    animation: infinite bounce 3s linear;
    transform: translate(50%, -25%);
    -ms-transform: translate(50%, -25%);
}

@keyframes bounce {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
        transform: translate(-50%, -25%);
        -ms-transform: translate(50%, -25%);
    }
    66% {
        opacity: 1;
        transform: translate(-50%, -5%);
        -ms-transform: translate(50%, -5%);
    }

    83% {
        opacity: 1;
        transform: translate(-50%, 5%);
        -ms-transform: translate(50%, 5%);
    }
    100% {
        transform: translate(-50%, 25%);
        -ms-transform: translate(50%, 25%);
        opacity: 0;
    }
}