#contactBottomContainer {
    height: 100vh;
    background-color: rgb(0, 200, 200);
    display: flex;
    align-items: center;
    justify-content: center;
}

#contactBottomForm {
    background-color: rgb(25, 31, 25);
    width: 90%;
    max-width: 600px;
    box-shadow: 10px 10px black;
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
}

.formTitle {
    display: inline-block;
}

.formTitle h1 {
    margin: 10px;
}

.underline {
    width: 0;
    height: 5px;
    margin: auto;
    background-color: white;
    transition: width 1s ease;
}

.underlineGrow {
    width: 100%;
}

#bottomCanvas {
    height: 200px;
    width: 100%;
}

.descSide {
    padding: 20px;
}
