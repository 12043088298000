.tplogo {
    margin: 0;
    height: 30px;
    user-select: none;
    padding: 20px 15px;
  }
  
  .logo:hover {
    cursor: pointer;
  }
  
  .navbarFlex {
    display: flex;
    max-width: 1200px;
    margin: auto;
  }
  
  .navbar {
    z-index: 1;
    position: fixed;
    width: 100%;
    border-bottom: 1px solid rgb(200, 200, 200);
    background-color: rgb(25, 31, 25);
    transition: visibility 0.3s ease, opacity 0.3s ease;
  }
  
  .icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    color: rgb(250, 250, 250);
    padding: 15px;
    transition: color 0.5s ease, transform 1s ease;
    transform: translateY(0);
  }
  
  .hideIcon {
    transform: translateY(-50vh);
  }
  
  .xIcon {
    z-index: 1;
    transition: color 0.5s ease, transform 1s ease 0.1s;
    transform: translateX(50vw);
  }
  
  .showXIcon {
    transform: translateX(0);
  }
  
  .icon:hover {
    color: rgb(0, 200, 200);
    cursor: pointer;
  }
  
  .mobileMenu {
    visibility: visible;
    color: white;
    position: absolute;
    width: 700px;
    height: 100vh;
    top: 70px;
    right: 0px;
    padding: 15px;
    background-image: linear-gradient(to right, rgba(25, 31, 25, 0), rgb(25, 31, 25));
    z-index: 1;
    font-size: xx-large;
    transform: translateX(100%);
    transition: transform 1s ease 0.5s;
  }
  
  .showMobileMenu {
    transition: transform 0.2s ease 0.1s;
    transform: translateX(0);
  }
  
  .mobileMenu ul {
    list-style: none;
  }
  
  .mobileLink {
    user-select: none;
    cursor: pointer;
    margin: 12px 0;
    align-items: center;
    flex-direction: row-reverse;
    transform: translateX(100vw);
    text-decoration: none;
    color: white;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  .currentLink {
    color: rgb(120, 120, 120);
  }

  .currentLink .linkedIcon path {
    fill: rgb(120, 120, 120);
  }
  
  .mobileLink:hover {
    color:rgb(0, 200, 200);
  }
  
  .mobileLink:hover .linkedIcon path {
    fill: rgb(0, 200, 200);
  }
  
  .linkedIcon path {
    fill: white;
    transition: fill 0.5s ease;
  }
  
  .homeLink {
    transition: color 0.5s ease, transform 0.6s ease 0.2s;
  }
  
  .contactLink {
    transition: color 0.5s ease, transform 0.6s ease 0.3s;
  }
  
  .linkedInLink {
    transition: color 0.5s ease, transform 0.6s ease 0.4s;
  }
  
  .gitHubLink {
    transition: color 0.5s ease, transform 0.6s ease 0.5s;
  }
  
  .showLink {
    transform: translateX(0);
  }
  
  .disableScroll {
    margin: 0;
    height: 100%;
    overflow: hidden;
  }
  
  .linkedIcon {
    width: 28px;
    height: 28px;
    margin: 10px;
  }
  
  .desktopMenu {
    visibility: none;
    height: 0;
    opacity: 0;
  }

  .desktopMenu ul {
    display: none;
  }

  .progress {
    position: fixed;
    width: 0%;
    height: 5px;
    z-index: 2;
    background-color: rgb(0, 200, 200);
    transition: width 0.2s ease, background-color 0.3s ease 1s;
  }

  .hideProgress {
    transition: width 0.2s ease, background-color 0.3s ease;
    background-color: rgb(25, 31, 25);
  }

  
  
  @media only screen and (min-width: 992px){
    .space {
      background-color: rgb(0, 200, 200);
    }
  
    .icon {
      display: none;
    }
  
    .xIcon {
      display: none;
    }
  
    .linkedIcon {
      height: 20px;
      width: 20px;
    }
  
    .desktopLink {
      user-select: none;
      cursor: pointer;
      margin: 0;
      text-decoration: none;
      color: white;
      display: flex;
      align-items: center;
      transition: color 0.5s ease;
    }

    .currentLink {
      color: rgb(120, 120, 120);
    }

    .currentLink .linkedIcon path {
      fill: rgb(120, 120, 120);
    }
  
    .desktopLink:hover {
      color: rgb(0, 200, 200);
    }
  
    .desktopLink:hover .linkedIcon path{
      fill: rgb(0, 200, 200);
    }

  
    .mobileMenu {
      display: none;
    }

    .desktopMenu ul {
      display: initial;
    }
  
    .desktopMenu {
      height: initial;
      visibility: visible;
      margin-left: auto;
      margin-right: 0;
      opacity: 1;
      transition: opacity 1s, visibility 0s ease;
    }
  
    .desktopMenu ul {
      list-style: none;
      display: flex;
      padding: 0 15px;
    }
  }