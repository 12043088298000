.footer {
    height: 100px;
    background-color: rgb(25, 31, 25);
    width: 100%;
    color: rgb(139, 139, 139);
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid white;
    text-align: center;
}

